<iframe
  #iframe
  src="assets/kor/index.html"
  width="100%"
  height="600px"
></iframe>

<div class="buttons">
  <kor-button
    label="Randomize"
    (click)="setRandomTheme()"
  ></kor-button>
  <kor-button
    label="Copy CSS"
    color="secondary"
    (click)="themeService.copyCSS()"
  ></kor-button>
  <kor-divider orientation="vertical"></kor-divider>
</div>

<kor-divider></kor-divider>

<ng-container *ngFor="let category of colorCategories">
  <h2>{{category.name}}</h2>
  <kor-grid
    fit-content
    spacing="l"
  >
    <div
      grid-cols="2"
      grid-cols-s="4"
      class="color-property"
      *ngFor="let property of category.properties"
    >
      <div
        class="swatch"
        [style.background-color]="'rgb(' + themeService.getPropertyValue(property) + ')'"
      >
        <input
          type="color"
          [value]="themeService.getPropertyValue(property) ? colorService.rgbToHex(themeService.getPropertyValue(property)) : ''"
          (change)="pickColor(property, $event.target)"
        />
      </div>
      <div class="text">
        <b>{{property}}</b>
        {{themeService.getPropertyValue(property)}}
      </div>
    </div>
  </kor-grid>
</ng-container>